<template>
        <v-container>
            <b-tabs content-class="mt-3">
                <b-tab title="Details" lazy>
                  <b-row class="bg-white rounded">
                    <b-col cols="4">
                      <b-img fluid src="@/assets/images/gallery/power.jpg" class="mt-4" />
                    </b-col>
                    <b-col cols="4">
                      <b-card title="Item Details" sub-title="">
                        <b-card-text><b>Item:</b> {{bookingService.name}}</b-card-text>
                        <b-card-text><b>Booking Date:</b> {{bookingService.purchase_price}}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col cols="4">
                      <b-card class="mb-4 text-center">
                        <img src="@/assets/images/users/7.jpg" class="img-fluid rounded-circle" width="65"/>
                        <h5 class="card-title mt-3 mb-2 pb-1">{{bookingUser.full_name}}</h5>
                        <h6 class="card-subtitle text-muted font-weight-normal">
                          {{bookingUser.phone}}
                        </h6>

                        <b-button block :href="'mailto:'+bookingUser.email" pill variant="danger" class="mt-3 d-flex align-items-center justify-content-center">
                          <feather type="mail" class="feather-sm mr-2"></feather> 
                          {{bookingUser.email}}
                        </b-button>
                      </b-card>
                    </b-col>
                    <b-col cols="6">
                      <b-card class="mb-4" bg-variant="info" text-variant="white" title="Offer">
                        <b-card-text><b>Offerred Price:</b> {{bookingData.offered_price}}</b-card-text>
                        <b-card-text><b>Booking Date:</b> {{bookingData.booking_date}}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col cols="6">
                      <b-card class="mb-4" bg-variant="success" text-variant="white" title="Payment">
                        <b-card-text><b>Payment Method:</b> {{bookingData.payment_method}}</b-card-text>
                        <b-card-text><b>Payment Status:</b> {{bookingData.payment_status==="0"? "Pending":"Paid" }}</b-card-text>
                      </b-card>
                    </b-col>
                    <!--<b-col cols="4">
                      <b-card class="mb-4" bg-variant="warning" text-variant="white" title="Comments">
                        <b-card-text><b>Comments:</b> {{bookingData.booking_comments}}</b-card-text>
                      </b-card>
                    </b-col>-->
                  </b-row>
                </b-tab>

                <b-tab title="Messages" lazy>
                    <div class="bg-white rounded">
                        <RecentChats :order="bookingData.id"  :type="'booking'"></RecentChats>
                    </div>
                </b-tab>

                <b-tab title="Contracts" lazy>
                <div class="bg-white">
                    <div v-if="updating" class="col-12 p-2">
                      <b-row class="border-bottom py-3 align-items-center">
                          <b-col sm="3" class="text-left text-md-right">
                              <label class="fw-medium mb-0">Offerred Price</label>
                          </b-col>
                          <b-col sm="9">
                              <b-form-group><b-form-input  v-model="updateForm.offered_price" ></b-form-input></b-form-group>
                          </b-col>
                      </b-row>
                      <b-row class="border-bottom py-3 align-items-center">
                          <b-col sm="3" class="text-left text-md-right">
                              <label class="fw-medium mb-0">Booking Terms</label>
                          </b-col>
                          <b-col sm="9">
                              <b-form-group><b-form-textarea id="comments" placeholder="Comments" v-model="updateForm.booking_comments" rows="3" max-rows="8" no-auto-shrink></b-form-textarea></b-form-group>
                          </b-col>
                      </b-row>
                    </div>
                    <div v-else class="col-12 p-2">
                      <p><b>Final Price:</b> {{ bookingData.offered_price }}</p>
                      <p><b>Booking Date:</b> {{ bookingData.booking_date }}</p>
                      <p><b>Contract Terms:</b><br><br>{{ bookingData.booking_comments }}</p>
                    </div>
                    <div class="col-12 p-2">
                      <b-button v-if="updating" class="btn" variant="warning" @click="updateBooking(1)">Update</b-button>
                      <b-button v-else class="btn" variant="warning" @click="enableUpdate()">Revise</b-button>
                      <b-button class="btn ml-2" variant="primary" @click="updateBooking(2)">Accept</b-button>
                      <b-button class="btn ml-2" variant="danger" @click="updateBooking(3)">Reject</b-button>
                    </div>
                </div>
                </b-tab>

                <b-tab title="Invoices" lazy>
                <div v-if="parseInt(this.bookingData.booking_status) > 1" class="bg-white p-2">
                    <br /><br />
                    <div class="card">
                    <div class="card-body">
                        <div class="container mb-5 mt-3">
                        <div class="container">
                            <div class="row">
                            <div class="col-xl-8">
                                <ul class="list-unstyled">
                                  <li class="text-muted">
                                      To: <span style="color: #5d9fc5">{{ bookingUser.full_name }}</span>
                                  </li>
                                  <li class="text-muted">{{ bookingUser.email }}</li>
                                  <li class="text-muted">
                                      <i class="fas fa-phone"></i> {{ bookingUser.phone }}
                                  </li>
                                </ul>
                            </div>
                            <div class="col-xl-4">
                                <p class="text-muted">Invoice</p>
                                <ul class="list-unstyled">
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="fw-bold">ID:</span>{{ bookingData.id }}
                                </li>
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="fw-bold">Creation Date: </span>{{ bookingData.booking_date }}
                                </li>
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="me-1 fw-bold">Status:</span
                                    ><span class="badge bg-warning text-black fw-bold">
                                    {{ bookingData.payment_status==="0"? "Pending":"Paid" }}</span
                                    >
                                </li>
                                </ul>
                            </div>
                            </div>

                            <div class="row my-2 mx-1 justify-content-center">
                            <table class="table table-striped table-borderless">
                                <thead
                                style="background-color: #84b0ca"
                                class="text-white"
                                >
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>{{ bookingService.name }}</td>
                                    <td>{{ bookingService.quantity }}</td>
                                    <td>{{ bookingData.offered_price }}</td>
                                    <td>{{ bookingData.offered_price }}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="row">
                            <div class="col-xl-8">
                                <p class="ms-3">
                                Add additional notes and payment information
                                </p>
                            </div>
                            <div class="col-xl-3">
                                <ul class="list-unstyled">
                                <li class="text-muted ms-3">
                                    <span class="text-black me-4">SubTotal</span> {{ bookingData.offered_price }}
                                </li>
                                <li class="text-muted ms-3 mt-2">
                                    <span class="text-black me-4">Tax(0%)</span> 0
                                </li>
                                </ul>
                                <p class="text-black float-start">
                                <span class="text-black me-3"> Total Amount</span
                                ><span style="font-size: 25px"> {{ bookingData.offered_price }}</span>
                                </p>
                            </div>
                            </div>
                            <hr />
                            <div class="row">
                            <div class="col-xl-10">
                                <p>Thank you for your purchase</p>
                            </div>
                            <div class="col-xl-2">
                                <span v-if="bookingData.booking_status === '4'">Payment Requested</span>
                                <span v-if="bookingData.booking_status === '5'">Payment Complete</span>
                                <button
                                v-if="bookingService.user_id === authId && bookingData.payment_status === '0' && bookingData.booking_status !== '4'"
                                type="button"
                                class="btn btn-primary"
                                @click="updateBooking(4)"
                                >
                                Request Payment
                                </button>
                                <button
                                v-if="bookingData.user_id === authId && bookingData.payment_status === '0'"
                                type="button"
                                class="btn btn-primary"
                                @click="updateBooking(5)"
                                >
                                Mark As Paid
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div v-else class="p-2">No Invoices</div>
                </b-tab>
            </b-tabs>
        </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RecentChats from "../../components/chats/RecentChats";
export default {
  data: () => ({
    updateForm: {
      offered_price: null,
      booking_comments: null,
    },
    mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#bbb',
          width: 400,
          height: 400,
          class: ''
    },
    bookingData:null,
    bookingService: null,
    bookingUser: null,
    updating: false
  }),
  components: {
    RecentChats,
  },
  computed: {
    ...mapState({
      bookings: (state) => state.booking.bookings,
      authId: (state) => state.auth.authId
    }),
    ...mapGetters(["bookingById"])
  },
  mounted() {
    this.loadBooking();
  },
  methods: {
    ...mapActions(["getBooking","getService","getUser","changeBooking"]),
    loadBooking(){
      this.getBooking({ id: this.$route.params.id }).then((res) => {
        this.bookingData = res[0];
        this.getService({id: this.bookingData.service_id}).then((ord) => {
          this.bookingService = ord[0];
          this.getUser({id: this.bookingData.user_id}).then((usr) => {
            this.bookingUser = usr[0]
          })
        })
      })
    },
    updateBooking(status){
      let nBooking = this.bookingData;
      nBooking.booking_status = status;
      if(this.updating){
        nBooking.offered_price = this.updateForm.offered_price;
        nBooking.booking_comments = this.updateForm.booking_comments;
      }
      if(status === 5){
        nBooking.payment_status = "1";
      }
      this.changeBooking(nBooking).then(res => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
          this.updating = false;
          this.loadBooking();
        }
      })
    },
    enableUpdate(){
      this.updating = true;
      this.updateForm.offerred_price = this.bookingData.offerred_price;
      this.updateForm.booking_comments = this.bookingData.booking_comments;
    }
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#container {
  height: 100%;
  width: 100%;
  font-size: 0;
}
#left,
#middle,
#right {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left {
  width: 50%;
}
#middle {
  width: 100%;
}
#right {
  width: 50%;
}
h4 {
  margin: 2rem 0rem 1rem;
}
#msgWindow {
  margin-top: 20px;
}

#msgs {
  margin: 0px 25px;
  min-height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.msg {
  margin: 5px 0;
  border: 1px solid silver;
  padding: 3px 7px;
  display: inline-block;
  position: relative;
  border-radius: 10px;
}
.msg::before,
.msg::after {
  content: "";
  display: inline-block;
  bottom: 0;
  position: absolute;
  border: 1px solid silver;
}
.msg::before {
  right: -20px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.msg::after {
  right: -35px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.msg.from {
  align-self: flex-end;
}
.msg.to {
  align-self: flex-start;
}
.msg.to::before {
  right: inherit;
  left: -20px;
}
.msg.to::after {
  right: inherit;
  left: -35px;
}
.msg.typing {
  color: silver;
}

#msgForm input:focus,
#msgForm button:focus {
  box-shadow: none;
}
</style>